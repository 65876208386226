html,
body {
  height: 100vh;
  width: 100vw;
}

#page {
  border: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.chart-wrapper {
  width: 46%;
  height: 46vh;
  padding: 1%;
  display: inline-block;
}

@media (max-width: 700px) {
  .chart-wrapper {
    width: 96%;
  }
}

.tile-rounded {
  border-radius: 0.5em;
  height: 50%;
}

.bg-dark-tile {
  background-color: #1c2138;
  color: #e2e8f0;
  transition: 0.2s;
}

.bg-dark-menu {
  background-color: #242a42;
  color: #e2e8f0;
  transition: 0.2s;
}

.bg-light-main {
  background-color: #e2e8f0;
  transition: 0.2s;
}

@keyframes onload-animate {
  0% {
    transform: translateY(-10%);
    opacity: 0.2;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.panel {
  /* This section calls the slideInFromLeft animation we defined above */
  animation: 0.5s ease-out 0s 1 onload-animate;
}

.bg-main {
  /* background-color: #5271ff !important; */
  border-right: 3px solid #5271ff;
  /* border-left: 3px solid transparent; */
  color: #5271ff;
  transition: 0.2s;
}

.bg-light-tile {
  background-color: #f7fafc;
  color: #718096;
  transition: 0.2s;
}

.bg-light-menu {
  background-color: #cbd5e0;
  color: #718096;
  transition: 0.2s;
}

.bg-dark-main {
  background-color: #171c30;
  transition: 0.2s;
}

.controlButton {
  transition: 0.1s ease;
  padding: 0%;
  outline: none;
}

.menubutton {
  transition: 0.1s ease;
  padding: 0%;
  font-size: 20px;
}

.disconnected {
  background: #ff4d4d;
  box-shadow: 0 0 0 rgba(218, 81, 81, 0.603);
  -moz-animation: disPulse 2s infinite;
  -webkit-animation: disPulse 2s infinite;
  animation: disPulse 2s infinite;
}

.connected {
  background: #16cf77;
  box-shadow: 0 0 0 rgba(22, 207, 119, 0.603);
  -moz-animation: connPulse 2s infinite;
  -webkit-animation: connPulse 2s infinite;
  animation: connPulse 2s infinite;
}

.pulseAnim {
  display: block;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

@keyframes disPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(218, 81, 81, 0.603);
  }
  70% {
    box-shadow: 0 0 0 14px rgba(218, 81, 81, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(218, 81, 81, 0);
  }
}

@keyframes connPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(22, 207, 119, 0.603);
  }
  70% {
    box-shadow: 0 0 0 14px rgba(22, 207, 119, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(42, 68, 56, 0);
  }
}

.disText {
  color: #ff4d4d;
}

.connText {
  color: #16cf77;
}

.x {
  color: #1c2138;
}

/* width */
.scroller::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.scroller::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.5px grey;
  border-radius: 10px;
}

/* Handle */
.scroller::-webkit-scrollbar-thumb {
  transition: ease 0.5s;

  opacity: 0.2;
  border-radius: 10px;
}

.scrollerDark::-webkit-scrollbar-thumb {
  background: #171c30;
}

.scrollerLight::-webkit-scrollbar-thumb {
  background: #e2e8f0;
}

.recordSelect {
  background-color: #00a669;
  color: white !important;
}

.sessionContainer {
  height: 18rem;
}

.loginForm {
  width: 31em;
  height: 35em;
}

button,
input,
canvas,
a {
  outline: none;
}

table tr {
  transition: 0.2s ease;
}

.nameColor {
  color: #5271ff;
}

.overlay {
  background-color: rgba(26, 32, 44, 0.4);
}

@media (hover: hover) and (pointer: fine) {
  table .row:hover * {
    background-color: #5271ff;
    cursor: pointer;
    color: white;
  }
  .linkHover {
    transition: 0.5s ease;
  }

  .linkHover:hover {
    text-decoration: underline;
  }

  /* Handle on hover */
  .scroller::-webkit-scrollbar-thumb:hover {
    background: #5271ff;
  }

  .x:hover {
    color: white;
  }

  .controlButton:hover {
    background-color: #5271ff;
    color: white;
  }

  .menubutton:hover {
    background-color: #5271ff;
    color: white;
  }
}

.vidCall {
  width: 100%;
  height: 200px;
}
